.Main {
  display: grid;
  background: #f5f5f5;
  padding: var(--space-large) var(--space-small);
  align-content: start;
  gap: var(--space-medium);
  grid-template-columns: minmax(0, 1fr);
}

.List {
  display: grid;
  justify-content: start;
  overflow: hidden;
  font-family: var(--main-font);
}

.Link {
  position: relative;
  text-transform: uppercase;
  list-style-type: none;
  font-size: 1rem;
  line-height: 1;
  padding: 0.5em 0;
  opacity: 0.5;
  transition:
    transform 150ms var(--ease-inout-cubic),
    opacity 150ms linear;
}

.Animation {
  --delay: 0;
  transform: translateY(100%);
  opacity: 0;
  transition:
    transform 500ms var(--ease-out-cubic),
    opacity 500ms linear;
  transition-delay: calc(250ms + var(--delay));
}

.Main.Visible .Animation {
  transform: translateY(0%);
  opacity: 0.5;
}

.Link:hover {
  opacity: 1;
  transform: translateX(3em);
}

.Link.Active {
  opacity: 1;
  transform: translateX(3em);
}

.Arrow {
  position: absolute;
  left: -2em;
}

.Archive {
  padding: var(--space-small) 0;
  overflow: hidden;
  font-family: var(--main-font);
}

.Archive a {
  text-transform: uppercase;
  display: flex;
  padding: 0.5em 0;
  transition: transform 150ms var(--ease-inout-cubic);
}

.Archive a:hover {
  transform: translateX(3em);
}

.Content {}

.Item {
  display: none;
  gap: var(--space-medium);
  grid-template-columns: minmax(0, 1fr);
}

.Item.Visible {
  display: grid;
}

.Title {
  text-transform: uppercase;
  overflow-wrap: break-word;
}

.Copy {}

.More {
  display: inline-block;
  white-space: nowrap;
}

.Info {
  display: flex;
  gap: var(--space-small);
  justify-content: space-between;
}

.Tickets {
  font-size: 0.8125rem;
  padding: 0.5em 2em;
  color: white;
  background-color: var(--accent-red);
  text-align: center;
}

@media (min-width: 1024px) {
  .Main {
    grid-template-columns: repeat(2, minmax(0, 50%));
    padding: var(--space-large);
  }

  .Item {
    gap: var(--space-small);
  }

  .Title {
    padding-bottom: var(--space-small);
  }

  .Tickets {
    justify-self: start;
  }
}