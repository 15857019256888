import { App, Controller } from './App'
import { VoidController } from './VoidController'
import styles from './Footer.module.css'

export class FooterController implements Controller {
  private readonly void: VoidController
  private readonly container: HTMLElement
  private inView = false

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)

    this.container = this.node.querySelector(`.${styles.Container}`) as HTMLElement
    this.void = new VoidController(this.container, app, false, 0.35)

    this.app.on('intersect', this.onIntersect)
    this.app.intersectionObserver.observe(this.container)
  }

  onIntersect() {}

  async load() {}

  async show() {}

  async hide() {}

  scroll() {}

  resize() {}

  dispose() {}

  update() {}
}
