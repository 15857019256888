.Main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100svh;
  /* background-image: url("../assets/images/background.png"); */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-color: transparent;
}

.Container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {}



/* Mobile */
@media (max-width: 768px) {

  .Main {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100svh;
    background-image: url("../assets/images/background_mobile.png");
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

}

/* Tablet and Smaller Desktop */
@media (min-width: 768px) and (max-width: 1120px) {}