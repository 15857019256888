.Main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.SvgContainer {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.SvgContainer svg {
    width: 100%;
    height: 100%;
    object-fit: cover;
}