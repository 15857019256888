.Main {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100svh;
  background: black;
  font-size: var(--title-size);
  font-family: var(--title-font);
  background-image: url("../assets/images/background_mobile.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.Container {
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
}

.Heading {
  position: absolute;
  color: white;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media (min-width: 1024px) {
  .Main {
    background-image: url("../assets/images/background.png")
  }
}

@media (min-width: 1440px) {
  .Main {
    background-image: url("../assets/images/background.png")
  }
}