.Main {
    position: absolute;
    width: 100%;
    height: 100%;
}

.Canvas {
    position: absolute;
    width: 100%;
    height: 100%;
}


/* Enable hover only on non-touch devices */
@media (hover: hover) and (pointer: fine) {}



/* Mobile */
@media (max-width: 768px) {
    .Canvas {
        pointer-events: none;
    }
}

/* Tablet and Smaller Desktop */
@media (min-width: 768px) and (max-width: 1120px) {}