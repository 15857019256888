import { App, Controller } from './App'
import styles from './SpeakerArchive.module.css'

export class SpeakerArchiveController implements Controller {
  private readonly buttons: NodeListOf<HTMLElement>
  private readonly items: Element[]
  private readonly openContainers = new Set<string>()
  private readonly copies: HTMLElement[]

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)
    this.toggleHeight = this.toggleHeight.bind(this)

    this.items = Array.from(this.node.querySelectorAll(`.${styles.Item}`))
    this.copies = Array.from(this.node.querySelectorAll(`.${styles.Copy}`) as NodeListOf<HTMLElement>)
    this.buttons = this.node.querySelectorAll(`.${styles.Button}`) as NodeListOf<HTMLElement>

    this.app.on('intersect', this.onIntersect)

    this.buttons.forEach((button) => button.addEventListener('click', this.toggleHeight))

    this.copies.forEach((copy, index) => {
      const button = this.buttons[index]
      const { scrollHeight, clientHeight } = copy
      if (scrollHeight > clientHeight) {
        button?.classList.add(styles.Visible)
        copy.classList.add(styles.Mask)
      }
    })
  }

  async show() {
    this.items.forEach((item) => this.app.intersectionObserver.observe(item))
  }

  toggleHeight = (event: Event) => {
    const button = event.currentTarget as HTMLElement

    const id = button.dataset.id
    if (!id) return

    const copy = this.copies.find((copy) => copy.dataset.id === id)
    if (!copy) return

    if (this.openContainers.has(id)) {
      copy.style.removeProperty('--height')
      copy.classList.remove(`${styles.Open}`)
      this.openContainers.delete(id)
    } else {
      this.openContainers.add(id)
      copy.style.setProperty('--height', 'auto')
      const { height } = copy.getBoundingClientRect()
      copy.style.removeProperty('--height')

      //next frame
      copy.offsetHeight
      copy.classList.add(`${styles.Open}`)
      copy.style.setProperty('--height', `${height}px`)
    }

    button?.textContent === 'Show more' ? (button.textContent = 'Hide') : (button.textContent = 'Show more')
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (this.items.includes(entry.target) && entry.isIntersecting) {
        entry.target.classList.add(`${styles.Visible}`)
      }
    })
  }

  dispose() {
    this.app.removeListener('intersect', this.onIntersect)
    this.items.forEach((item) => this.app.intersectionObserver.unobserve(item))
  }
}
