.Main {}

.Inner {
  padding: var(--space-large) 0;
  display: grid;
  gap: var(--space-large);
}

.Wrapper {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;
  padding: 0 var(--space-small);
  z-index: 100;
}

.Back {}

.Content {
  display: grid;
  gap: var(--space-medium);
  grid-template-columns: minmax(0, 1fr);
}

.Title {
  text-transform: uppercase;
}

.Text h3,
.Text h4,
.Text h5 {
  text-transform: uppercase;
  font-size: inherit;
}

.Links {
  display: flex;
  gap: 1em;
}

.Link {
  display: inline-block;
  text-decoration: underline;
}

.Info {
  display: flex;
  gap: 3em;
  justify-content: space-between;
}

.Info h5 {
  text-transform: uppercase;
}

.Arrow {
  position: absolute;
  font-size: var(--title-size);
  font-family: var(--title-font);
  line-height: 1;
  color: white;
}

.NotVisible {
  --delay: 0;
  transform: translateY(14vh);
  opacity: 0;
  transition-delay: var(--delay);
  transition:
    opacity 0.5s var(--easing),
    transform 0.5s var(--easing);
}

.Animate {
  transform: translateY(0vh);
  opacity: 1;
}

.Container {}

.Slider {
  aspect-ratio: 2;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.Slides {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Image {
  max-height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.Arrow:hover {
  cursor: pointer;
}

.ArrowRight {
  right: 4%;
}

.ArrowLeft {
  left: 4%;
}

.Tickets {
  font-size: 0.8125rem;
  padding: 0.5em 2em;
  color: white;
  background-color: var(--accent-red);
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .Wrapper {
    grid-template-columns: minmax(0, 60ch);
  }

  .Back {
    position: fixed;
    left: var(--space-small);
  }

  .Inner {
    gap: var(--space-medium);
  }

  .Container {
    padding: 0 var(--space-medium);
  }

  .Slider {}

  .Slides {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ArrowRight {
    right: 1%;
  }

  .ArrowLeft {
    left: 1%;
  }
}