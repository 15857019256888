.Main {
  background: white;
}

.Container {
  position: relative;
  aspect-ratio: 3/4;
  background-color: #f8f8f8;
}

.Video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Content {
  padding: var(--space-medium) var(--space-small);
  display: grid;
  gap: var(--space-medium);
}

.Heading {
  font-size: var(--title-size);
  font-family: var(--title-font);
  line-height: 1;
}

.NotVisible {
  transform: translateY(14vh);
  opacity: 0;
  --timeline: cubic-bezier(0.18, 0.95, 0.7, 1.0);
  transition: opacity 0.5s var(--timeline), transform 0.5s var(--timeline);
}

.Animate {
  transform: translateY(0vh);
  opacity: 1;
}

.Link a {
  display: block;
  font-size: 0.8125rem;
  padding: 0.5em 2em;
  color: white;
  background-color: var(--accent-red);
  text-align: center;
}

@media screen and (min-width: 1024px) {
  .Container {
    aspect-ratio: 2;
  }

  .Logo {
    left: 25%;
    width: 50%;
  }

  .Content {
    grid-template-columns: repeat(2, minmax(0, 50%));
    padding: var(--space-large);
  }

  .Link {
    grid-column: 2;
  }

  .Link a {
    display: inline-block;
  }
}