.Main {
  position: relative;
  min-height: 50vh;
  /* background-image: url("../assets/images/background_mobile.png"); */
  background-color: var(--background-green);
  color: white;
  font-family: var(--main-font);
  font-size: 0.8125rem;
  text-transform: uppercase;
  line-height: 1.35;
  background-size: 100% 100%;

}

.Container {
  position: absolute;
  width: 100%;
  height: 100%;
}

.Inner {
  pointer-events: none;
  position: relative;
  padding: var(--space-large) var(--space-small);
  display: grid;
}

.Column {
  padding-left: 2em;
  display: grid;
  gap: 20vw;
}

.Column p {
  padding-bottom: 1em;
}

.Column a {
  position: relative;
  color: inherit;
  text-decoration: none;
  pointer-events: auto;
}

.Column a:before {
  content: '↗';
  position: absolute;
  left: -2em;
}

.Column h6 {
  font-size: inherit;
  font-weight: normal;
  padding-bottom: 2em;
  box-sizing: content-box;
}

@media (min-width: 1024px) {
  .Main {
    min-height: 0;

  }

  .Inner {
    grid-auto-flow: column;
    justify-content: space-between;
    padding: 15vw 5vw;
  }

  .Column {
    grid-auto-flow: column;
    gap: 5vw;
  }

  .Column h6 {
    min-height: 2.7em;
  }
}