import { App, Controller } from './App'
import { delay } from '../utils/delay'
import { ReactController } from './ReactController'

export class IntroControllerReact implements Controller {
  private inView = false
  private readonly react: ReactController = ReactController.prototype

  constructor(
    private readonly node: HTMLElement,
    private readonly app: App,
  ) {
    this.onIntersect = this.onIntersect.bind(this)
    this.react = new ReactController(this.node, app)

    this.app.on('intersect', this.onIntersect)
    this.app.intersectionObserver.observe(this.node)
  }

  onIntersect(entries: IntersectionObserverEntry[]): void {
    entries.forEach((entry) => {
      if (entry.target === this.node) {
        this.inView = entry.isIntersecting
      }
    })
  }

  scroll() {
    this.react.scroll()
  }

  resize() {
    this.react.resize()
  }

  async show() {}

  async hide() {}
}
