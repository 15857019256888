.Main {
  padding: var(--space-large) 0;
  display: grid;
  gap: var(--space-large);
}

.Title {
  text-transform: uppercase;
}

.Wrapper {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  justify-content: center;
  padding: 0 var(--space-small);
}

.Grid {
  padding: var(--space-medium) 0;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  gap: var(--space-large);
}

.Animate {
  transform: translateY(0vh);
  opacity: 1;
}

.Item {
  --delay: 0;
  height: fit-content;
  transform: translateY(15vh);
  opacity: 0;
  transition:
    opacity 500ms var(--ease-out-cubic),
    transform 500ms var(--ease-out-cubic);
  transition-delay: var(--delay);
}

.Visible {}

.Item.Visible {
  transform: translateY(0);
  opacity: 1;
}

.Inner {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.ImageContainer {
  aspect-ratio: 17/10;
  overflow: hidden;
  position: relative;
  background-color: var(--placeholder-color);
}

.Image {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.Copy {
  --height: 360px;
  overflow: hidden;
  max-height: var(--height);
  padding: var(--space-small) 0;
  transition: max-height 300ms var(--ease-inout-cubic);
}

.Mask {
  mask-image: linear-gradient(to bottom, black 60%, rgba(0, 0, 0, 0) 95%);
}

.Open {
  mask-image: none;
}

.Copy h3 {
  font-family: var(--main-font);
  line-height: 1;
  text-transform: uppercase;
  padding-bottom: 1em;
}

.Copy h5 {
  font-family: var(--main-font);
  padding-bottom: 2em;
  font-size: 16px;
}

.Copy p {
  padding-bottom: 1em;
}

.Button {
  display: none;
  text-transform: uppercase;
  padding: 1em 0;
}

.Button.Visible {
  display: block;
}

@media screen and (min-width: 1024px) {
  .Wrapper {
    padding: 0 var(--space-medium) 0 30%;
  }

  .Back {
    position: fixed;
    left: var(--space-small);
  }

  .Grid {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: var(--space-small);
  }

  /*.SpeakersContainer {*/
  /*  display: grid;*/
  /*  grid-template-columns: 1fr 1fr;*/
  /*  column-gap: 1.5rem;*/
  /*  row-gap: 5.5rem;*/
  /*}*/

  /*.SpeakerContainer {*/
  /*  --height: 600px;*/
  /*}*/

  /*.Main h5 {*/
  /*  padding-bottom: 2vh;*/
  /*}*/

  /*.ImageContainer {*/
  /*  height: 18em;*/
  /*}*/
}